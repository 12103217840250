import { forwardRef } from 'react';
import type { ButtonProps as ChakraButtonProps } from '@chakra-ui/react';
import { AbsoluteCenter, Button as ChakraButton, Span, Spinner } from '@chakra-ui/react';

interface ButtonLoadingProps {
	loading?: boolean;
	loadingText?: React.ReactNode;
}

export interface ButtonProps extends ChakraButtonProps, ButtonLoadingProps {}

// eslint-disable-next-line react/display-name
export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
	const { loading, disabled, loadingText, children, ...rest } = props;
	return (
		<ChakraButton disabled={loading || disabled} ref={ref} {...rest}>
			{/* eslint-disable-next-line no-nested-ternary */}
			{loading && !loadingText ? (
				<>
					<AbsoluteCenter display="inline-flex">
						<Spinner size="inherit" color="inherit" />
					</AbsoluteCenter>
					<Span opacity={0}>{children}</Span>
				</>
			) : loading && loadingText ? (
				<>
					<Spinner size="inherit" color="inherit" />
					{loadingText}
				</>
			) : (
				children
			)}
		</ChakraButton>
	);
});
